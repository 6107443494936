import { defineMessages } from 'react-intl';

const createMessage = (id, description = 'no description', values = {}) => ({
  id,
  description,
  defaultMessage: 'UNTRANSLATED',
  values,
});

const messages = defineMessages({
  header: {
    composer: createMessage('header@composer'),
    sounddesigner: createMessage('header@sounddesigner'),
    orchestrachief: createMessage('header@orchestrachief'),
  },
  footer: {
    copyrights: createMessage('footer@copyrights'),
  },
  menu: {
    lastNews: createMessage('menu@last-news'),
    activities: createMessage('menu@activities'),
    music: createMessage('menu@music'),
    resume: createMessage('menu@resume'),
    contact: createMessage('menu@contact'),
  },
  block: {
    topTracks: createMessage('block@top-tracks'),
    lastNews: createMessage('block@last-news'),
    allNews: createMessage('block@all-news'),
    music: createMessage('block@music'),
    resume: createMessage('block@resume'),
    resumeDetails: createMessage('block@resume-details'),
    contact: createMessage('block@contact'),
  },
  contact: {
    contactMe: createMessage('contact@contact-me'),
    contactAgent: createMessage('contact@contact-agent'),
  },
  language: {
    french: createMessage('language@french'),
    english: createMessage('language@english'),
  },
});

export default messages;
