import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { darken } from 'polished';

const IconStyle = styled.div`
  font-size: 1.6rem;
  color: #565656;

  &:hover {
    color: ${darken(10, '#565656')};
    cursor: pointer;
  }

  /* Small only */
  @media screen and (max-width: 39.9375em) {
    font-size: 3rem;
  }

  /* Medium and up */
  @media screen and (min-width: 40em) {}

  /* Medium only */
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {}

  /* Large and up */
  @media screen and (min-width: 64em) {}

  /* Large only */
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {}
`;

/* eslint-disable */
class SocialLink extends React.Component {
  static propTypes = {
    faName: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    className: PropTypes.string,
    children: PropTypes.node,
  }

  static defaultProps = {
    className: '',
  }

  onClick = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  }

  render() {
    const { faName, url, className, children } = this.props;
    return (
      <IconStyle
        className={`${className} social-link`}
        type="button"
        onClick={() => this.onClick(url)}
      >
        {
          children
        }
        {/* <i className={`fab ${faName}`} /> */}
      </IconStyle>
    )
  }
}

export default SocialLink;
