import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'gatsby-plugin-intl';

import messages from '../../i18n/messages';

const { footer } = messages;

const Container = styled.footer`
  text-align: center;
  margin-bottom: 10px;
`;

const Footer = () => (
  <Container>
    <div>
      <FormattedMessage {...footer.copyrights} />
    </div>
  </Container>
);

export default Footer;
