import React from 'react';
import styled from 'styled-components';
import { Link, injectIntl, FormattedMessage } from 'gatsby-plugin-intl';

import messages from '../../i18n/messages';

const { menu } = messages;

const NavContainer = styled.nav`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  a {
    color: var(--link-color);
    margin: 0 1rem;
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 14px;
  }

  a:hover {
    text-decoration: underline;
    cursor: pointer;
  }


  /* Small only */
  @media screen and (max-width: 39.9375em) {
    a {
      text-align: center;
    }
    flex-direction: column;
  }

  /* Medium and up */
  @media screen and (min-width: 40em) {}

  /* Medium only */
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {}

  /* Large and up */
  @media screen and (min-width: 64em) {}

  /* Large only */
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {}
`;

const activeStyle = {
  color: 'var(--active-color)',
};

const NavBar = () => (
  <NavContainer>
    <Link to="/" activeStyle={activeStyle}>
      <FormattedMessage {...menu.activities} />
    </Link>
    <Link to="/music" activeStyle={activeStyle}>
      <FormattedMessage {...menu.music} />
    </Link>
    <Link to="/resume" activeStyle={activeStyle}>
      <FormattedMessage {...menu.resume} />
    </Link>
    <Link to="/contact" activeStyle={activeStyle}>
      <FormattedMessage {...menu.contact} />
    </Link>
  </NavContainer>
);

export default injectIntl(NavBar);
