/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'gatsby-plugin-intl';

import Header from './header/Header';
import NavBar from './navbar/NavBar';
import Footer from './footer/Footer';

import './constants.css';
import './layout.css';
import './app.css';

/* eslint-disable */
const Layout = ({ children, intl }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  return (
    <>
      <Header siteTitle={intl.formatMessage({ id: 'title' })} />
      <div
        style={{
          margin: '0 auto',
          // maxWidth: 720, // 960,
          padding: '0px 1.0875rem 1.45rem',
          paddingTop: 0,
        }}
      >
        <NavBar />
        <main>{children}</main>
        <Footer />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.shape({}),
  }).isRequired,
};

export default injectIntl(Layout);
