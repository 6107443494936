import React from 'react';
import styled from 'styled-components';
import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-intl';

const languageName = {
  en: 'English',
  fr: 'French',
};

const LocaleLink = styled.a`
  color: var(--link-color) !important;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none !important;
  margin: 0 4px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
    color: var(--active-color) !important;
  }
`;


/* eslint-disable */
const Language = () => (
  <IntlContextConsumer>
    {
      ({ languages, language: currentLocale }) => (
        languages.map(language => (
          <LocaleLink
            key={language}
            onClick={() => changeLocale(language)}
            style={{
              color: currentLocale === language ? 'blue' : 'red',
              margin: 10,
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            {languageName[language]}
          </LocaleLink>
        ))
      )
    }
  </IntlContextConsumer>
);

export default Language;
