import React from 'react';
import styled from 'styled-components';
import { FaFacebook, FaSoundcloud } from 'react-icons/fa';
import { Link, injectIntl, FormattedMessage } from 'gatsby-plugin-intl';

import messages from '../../i18n/messages';
import SocialLink from './SocialLink';
import Language from '../language/Language';

const Profile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0 20px;

  div#name {
    font-size: 1.8rem;
    margin-bottom: 0.1em;
    color: var(--main-text-color);
    text-transform: uppercase;
  }

  div#desc {
    font-size: 1.4rem;
    span { margin: 0 10px; }
  }

  /* Small only */
  @media screen and (max-width: 39.9375em) {
    div#name {
      margin-top: 60px;
    }

    div#desc {
      font-size: 1rem;
      text-align: center;
      line-height: 16px;
      span { margin: 0 10px; display: block; }
      .sep {
        display: none;
      }
    }
    margin: 40px 0;
  }

  /* Medium and up */
  @media screen and (min-width: 40em) {}

  /* Medium only */
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {}

  /* Large and up */
  @media screen and (min-width: 64em) {}

  /* Large only */
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {}

  div#social {
    display: flex;
    .social-link {
      margin: 4px 4px 0;
    }
  }

  div#language {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  a {
    color: var(--link-color) !important;
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    margin: 0 4px;
  }

  a:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const { header } = messages;

const Title = styled.div`
  margin-bottom: 20px !important;
`;

const Sep = () => <span className="sep">|</span>;

/* eslint-disable */
const Header = ({ intl }) => (
  <Profile>
    <div id="language">
      <Language />
    </div>
    <Link to="/">
      <Title id="name">Cyril Planchon</Title>
    </Link>
    <div id="desc">
      <FormattedMessage {...header.composer} />
      <Sep />
      <FormattedMessage {...header.sounddesigner} />
      <Sep />
      <FormattedMessage {...header.orchestrachief} />
    </div>
    <div id="social">
      <SocialLink
        url="https://www.imdb.com/name/nm7318295/"
      >
        <div>IMDB</div>
      </SocialLink>
      <SocialLink
        faName="fa-facebook"
        url="https://www.facebook.com/cyril.planchon.5"
      >
        <FaFacebook />
      </SocialLink>
      <SocialLink
        faName="fa-soundcloud"
        url="https://soundcloud.com/cyrilplanchon"
      >
        <FaSoundcloud />
      </SocialLink>
      {/* <EmailLink /> */}
    </div>
  </Profile>
);


export default injectIntl(Header);
